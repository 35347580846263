import { Fragment, FunctionalComponent, h } from 'preact';
import Helmet from 'preact-helmet';
import style from './Termekek.scss';
import Button from 'components/Button/Button';
import LayoutSelector from 'components/LayoutSelector/LayoutSelector';
import { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'preact/hooks';

import TermekekcsoportokFloatingMenu from 'routes/Termekek/TermekekcsoportokFloatingMenu';
import { ApiServiceInstance, TermekDTO, TermekcsoportDTO } from 'services/api-service';
import Termek from 'routes/Termekek/Termek';
import { Services } from 'components/App/App';
import Loading from 'components/Loading/Loading';
import { Constant } from 'components/App/Constant';

const Termekek: FunctionalComponent<{ termekcsoport: string }> = (props) => {
  const services = useContext(Services);
  const gt_sm = services.breakpoint.value.isMatch('gt_sm');
  const defaultLayoutType = services.breakpoint.value.isMatch('lt_sm') ? '1' : services.breakpoint.value.isMatch('lt_md') ? '2' : '3';

  const [layoutType, setLayoutType] = useState<1 | 2 | 3>(() => {
    if (typeof window !== 'undefined') {
      return Number(localStorage.getItem('TERMEKEK_LAYOUT') || defaultLayoutType) as 1 | 2 | 3;
    }
    return 2;
  });

  const selectLayout = useCallback(
    (selected) => {
      localStorage.setItem('TERMEKEK_LAYOUT', selected.toString());
      setLayoutType(selected);
    },
    [setLayoutType]
  );

  const [isLoading, setLoading] = useState(false);
  const isLoadingRef = useRef(isLoading);

  useEffect(() => {
    isLoadingRef.current = isLoading;
  }, [isLoading]);

  const spinnerRef = useRef(null);

  const [termekData, setTermekData] = useState({
    termekcsoport: null as TermekcsoportDTO | null,
    termekList: [] as TermekDTO[],
    totalCount: 0,
    currentPage: 0,
    pageSize: 18,
    neverLoaded: true,
  });

  const fetchNextTermekList = useCallback(async () => {
    const api = ApiServiceInstance;
    setLoading(true);
    if (termekData.termekcsoport) {
      const res = await api.getTermekList({
        termekcsoportId: termekData.termekcsoport.id,
        page: termekData.currentPage + 1,
        pageSize: termekData.pageSize,
      });
      setTermekData((p) => ({
        ...p,
        termekList: [...p.termekList, ...res.items],
        currentPage: termekData.currentPage + 1,
        neverLoaded: false,
      }));
    }
    setLoading(false);
  }, [termekData]);

  useEffect(() => {
    let observer: IntersectionObserver | null = null;

    if (spinnerRef.current) {
      observer = new IntersectionObserver((entries) => {
        entries.forEach((e) => {
          if (e.isIntersecting && !isLoadingRef.current) {
            fetchNextTermekList();
          }
        });
      });

      observer.observe(spinnerRef.current);
    }

    return () => observer?.disconnect();
  }, [fetchNextTermekList, spinnerRef]);

  useEffect(() => {
    async function fetch(): Promise<void> {
      const api = ApiServiceInstance;
      const termekcsoportList = await api.getTermekcsoportList();
      const termekcsoport = termekcsoportList.find(
        (i) => i.urlPart === props.termekcsoport || (!props.termekcsoport && i.urlPart === 'alapveto-elelmiszerek')
      );

      setTermekData((p) => ({
        termekcsoport,
        termekList: [],
        totalCount: 0,
        currentPage: 0,
        pageSize: p.pageSize,
        neverLoaded: true,
      }));

      if (termekcsoport) {
        setLoading(true);
        const res = await api.getTermekList({ termekcsoportId: termekcsoport.id, page: 1, pageSize: termekData.pageSize });
        setTermekData((p) => ({
          ...p,
          totalCount: res.totalCount,
          termekList: res.items,
          currentPage: 1,
          neverLoaded: false,
        }));
        setLoading(false);
      }
    }
    fetch();
  }, [props.termekcsoport]);

  const [state, setState] = useState({ termekekMenuOpened: false });

  const termekekLinkRef = useRef(null);
  const termekekMenuRef = useRef(null);
  const setTermekekMenuOpened = useCallback(() => {
    setState((p) => ({ ...p, termekekMenuOpened: true }));
  }, []);
  const setTermekekMenuClosed = useCallback(() => {
    setState((p) => ({ ...p, termekekMenuOpened: false }));
  }, []);

  useLayoutEffect(() => {
    if (termekekLinkRef.current && termekekMenuRef.current?.base) {
      termekekMenuRef.current.base.style.left = `${termekekLinkRef.current.offsetLeft - termekekMenuRef.current.clientWidth}px`;
      termekekMenuRef.current.base.style.top = `${termekekLinkRef.current.offsetTop + termekekLinkRef.current.clientHeight}px`;
    }
  });

  useEffect(() => {
    setTermekekMenuClosed();
  }, [termekData.termekcsoport, setTermekekMenuClosed]);

  const rowLayout = gt_sm && layoutType === 1;

  return (
    <Fragment>
      <Helmet
        title="Termékek"
        meta={[
          {
            name: 'description',
            content: `A ${Constant.title.full} weboldala. Élelmiszer és vegyiáru kereskedelem és szállítás. - ${
              termekData?.termekcsoport?.id === -1 ? 'Aktuális ajánlatok.' : `Termékek: ${termekData?.termekcsoport?.nev}.`
            }`,
          },
        ]}
      />
      <div class="page">
        {!props.termekcsoport && (
          <div class={`page__header ${style.page__header}`}>
            <h1 class="flyin-left">Termékek</h1>
          </div>
        )}
        <div class={style['items-header-container']}>
          <div />
          <div class={`fx-r ai-center ${style['items-header']}`}>
            <div
              class="fx-r fx-pi-start-center"
              style="height: 100%"
              ref={termekekLinkRef}
              onMouseEnter={setTermekekMenuOpened}
              onMouseLeave={setTermekekMenuClosed}
            >
              <h2 class="pointer mrg-0 fx-r">
                {termekData.termekcsoport ? termekData.termekcsoport.nev : 'Termékek'} <img src="assets/icon/arrow_down_black.svg" />
              </h2>
              {state.termekekMenuOpened && <TermekekcsoportokFloatingMenu ref={termekekMenuRef} />}
            </div>
            <div class="fx-fill" />
            <div class="fx-r fx-pi-center-center pad-r-2">
              {termekData.termekList.length} / {termekData.totalCount}
            </div>
            <LayoutSelector selected={layoutType} onSelect={selectLayout} />
          </div>
        </div>
        <div class={`page__content ${style.page__content}`}>
          <div class={`page__content__section  ${style.page__content__section}`}>
            <div style="width: 100%; max-width: 1280px;" class="fx-r jc-end">
              <span class="italic">Az árak forintban és nettóban értetendőek.</span>
            </div>
            <div class={'page__content__section__layout'}>
              <div
                class={`${style.items} ${
                  layoutType === 1
                    ? style['items--columns-1']
                    : layoutType === 2
                    ? style['items--columns-2']
                    : layoutType === 3
                    ? style['items--columns-3']
                    : null
                } ${rowLayout ? style['items--row-layout'] : null}`}
              >
                {termekData?.termekList?.map((termek) => (
                  <Termek
                    key={termek.id}
                    name={termek.nev}
                    imgSrc={ApiServiceInstance.getImageLink(termek.kep)}
                    rowLayout={rowLayout}
                    ar={termek.ar}
                    colImgMaxHeight={defaultLayoutType === '1' ? 120 : 200}
                  />
                ))}
                {((termekData.termekList.length === 0 && termekData.totalCount > 0) ||
                  termekData.termekList.length < termekData.totalCount) && (
                  <div class="fx-r fx-pi-center-center" style="width: 100%; grid-column: 1 / -1">
                    <div class="hide-lt-md">
                      <Loading spinnerRef={spinnerRef} />
                    </div>
                    <div class="hide-gte-md">
                      <Button
                        label={`További termékek (${termekData.totalCount - termekData.termekList.length})`}
                        onClick={fetchNextTermekList}
                      />
                    </div>
                  </div>
                )}
                {termekData.termekList.length === 0 && termekData.totalCount === 0 && !termekData.neverLoaded && (
                  <span>{(termekData?.termekcsoport?.id === -1 ? 'Feltöltés alatt!' : 'Nincs ilyen termék!')}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Termekek;
